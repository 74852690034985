var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    attrs: {
      "enctype": "multipart/form-data"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.sendFile.apply(null, arguments);
      }
    }
  }, [_vm.message ? _c('div', {
    class: "message ".concat(_vm.error ? 'is-danger' : 'is-success')
  }, [_c('div', {
    staticClass: "message-body"
  }, [_vm._v(_vm._s(_vm.message))])]) : _vm._e(), _c('div', {
    staticClass: "dropzone"
  }, [_c('input', {
    ref: "file",
    staticClass: "input-file",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.sendFile
    }
  }), !_vm.uploading ? _c('div', {
    staticClass: "call-to-action"
  }, [_c('img', {
    staticClass: "dropzone-label-icon",
    attrs: {
      "src": require("@/assets/images/upload.png"),
      "alt": "upload"
    }
  }), _c('p', [_vm._v("Drag & Drop")]), _c('p', [_vm._v("Or")])]) : _vm._e(), _vm.uploading ? _c('p', {
    staticClass: "progress-bar"
  }) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }