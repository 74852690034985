var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dropzone"
  }, [_c('form', {
    attrs: {
      "enctype": "multipart/form-data"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.sendFile.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "file"
  }, [_c('label', {
    staticClass: "file-label"
  }, [_c('input', {
    ref: "file",
    staticClass: "file-input",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.selectFile
    }
  }), _vm._m(0), _vm.file ? _c('span', {
    staticClass: "file-name"
  }, [_vm._v(_vm._s(_vm.file.name))]) : _vm._e()])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "file-cta"
  }, [_c('span', {
    staticClass: "file-label"
  }, [_vm._v(" Select File ")])]);
}]

export { render, staticRenderFns }