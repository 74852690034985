<template>
  <div class="dropzone">
    <form @submit.prevent="sendFile" enctype="multipart/form-data">
      <div class="field">
        <div class="file">
          <label class="file-label">
            <input type="file" ref="file" @change="selectFile" class="file-input" />
            <span class="file-cta">
              <span class="file-label"> Select File </span>
            </span>
            <span v-if="file" class="file-name">{{ file.name }}</span>
          </label>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'DropZone',
  data() {
    return {
      file: ''
    }
  },
  methods: {
    selectFile() {
      this.file = this.$refs.file.files[0]
      localStorage.setItem('file', this.file.name)
    },
    async sendFile() {
      const formData = new FormData()
      formData.append('file', this.file)
      try {
        await axios.post('/upload', formData)
      } catch (err) {}
    }
  }
}
</script>
<style scoped>
.dropzone {
  overflow-wrap: break-word;
  padding: 12px;
  text-align: center;
}
.file-input {
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  color: black;
}
.file-label {
  background-color: orange;
  padding: 5px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
}
</style>
