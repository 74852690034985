<template>
  <form @submit.prevent="sendFile" enctype="multipart/form-data">
    <div v-if="message" :class="`message ${error ? 'is-danger' : 'is-success'}`">
      <div class="message-body">{{ message }}</div>
    </div>

    <div class="dropzone">
      <input type="file" class="input-file" ref="file" @change="sendFile" />
      <div v-if="!uploading" class="call-to-action">
        <img class="dropzone-label-icon" src="@/assets/images/upload.png" alt="upload" />
        <p>Drag & Drop</p>
        <p>Or</p>
      </div>
      <p v-if="uploading" class="progress-bar"></p>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  name: 'DropZone',
  data() {
    return {
      file: '',
      message: '',
      error: false,
      uploading: false
    }
  },
  methods: {
    async sendFile() {
      const file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('file', file)
      try {
        await axios.post('/dropzone', formData)
      } catch (err) {
        this.message = err.response.data.error
        this.error = true
      }
    }
  }
}
</script>
<style scoped>
.dropzone {
  overflow-wrap: break-word;
  padding: 12px;
  position: relative;
  /* border: 1px solid red; */
  /* background: lightcyan; */
  color: dimgray;
  min-height: 100px;
}
/* .dropzone:hover {
  background: lightblue;
} */
.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
.dropzone .call-to-action {
  text-align: center;
}
.dropzone .call-to-action img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/*.file-input {
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  color: black;
}
.file-label {
  background-color: orange;
  padding: 5px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
}
.input-file {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.dropzone .call-to-action {
  text-align: center;
}
.dropzone .call-to-action img {
  display: block;
  margin-left: auto;
  margin-right: auto;
} */
</style>
